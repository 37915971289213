$caret-size: 8px;
$select-height: 20px;

// Default select (mobile)
.kr-select-wrapper.kr-real-select {
  select {
    width: 100%;
    background: #fff;
    border: 1px solid #000;
  }

  .kr-select-caret {
    display: none;
  }

  .kr-select-caret svg {
    width: $caret-size;
    height: $caret-size;
  }
}

// Custom select (desktop)
.kr-select-wrapper.kr-custom-select {
  @include flex-direction(column);
  position: relative;

  .kr-select {
    user-select: none;
    border: 1px solid #000;
    position: relative;
    cursor: default;

    .kr-select-caret {
      position: absolute;
      right: 5px;
    }

    .kr-select-caret svg {
      width: $caret-size;
      height: $caret-size;
    }
  }

  .kr-options {
    user-select: none;
    position: absolute;
    width: 100%;
    max-height: $select-height * 6;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px;
    z-index: 10000;
    cursor: default;

    .kr-option {
      user-select: none;
      cursor: default;

      &:hover {
        background-color: #ccc;
      }
    }

    &.top {
      &.kr-options-6 {
        top: -$select-height * 6;
      }
      &.kr-options-5 {
        top: -$select-height * 5;
      }
      &.kr-options-4 {
        top: -$select-height * 4;
      }
      &.kr-options-3 {
        top: -$select-height * 3;
      }
      &.kr-options-2 {
        top: -$select-height * 2;
      }
      &.kr-options-1 {
        top: -$select-height * 1;
      }
    }

    &.bottom {
      top: $select-height - 2px;
    }

    &:not(:hover) {
      .kr-option.kr-active-option {
        background-color: #ccc;
      }
    }
  }
}

.kr-identity-document-type {
  .kr-select-wrapper.kr-real-select {
    span.kr-select-placeholder {
      position: absolute;
      pointer-events: none;
    }
  }
}
