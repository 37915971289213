.kr-popin-modal-footer {
    .kr-whitelabel-logo img {
        margin-right: $sides-margin;
    }
}

&.kr-small-popin {
    padding-bottom: 30px;
    .kr-popin-modal-footer {
        height: 30px;
    }
}