// Card icons
.kr-card-icons {
  display: flex;
  flex-direction: row;

  span {
    display: inline-block;
    margin: 0;
    cursor: default;

    :not(.kr-extra-brands) {
      background-color: #fff;
    }

    &.kr-extra-brands {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #777;

      @import './card_tooltip';
    }
  }
  span,
  span svg {
    box-sizing: content-box;
    height: 16px;
    width: 20px;
  }
}
