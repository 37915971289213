%font-style {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #444;
}

.kr-smartform-methods {
  .kr-methods-list {
    .kr-methods-list-header {
      @extend %font-style;
    }

    .kr-methods-list-options {
      .kr-methods-group-activator,
      .kr-method-label {
        @extend %font-style;
        margin: 10px 0px 10px 0px;
        padding: 15px 20px 15px 47px;
        width: 100%;
        border: 1px solid #b7b7b7;
        box-shadow: rgba(149, 157, 165, 0.15) 0px 0 24px;
        transition: .3s background-color;
        
        @import './card_icons';
        @import './apple_pay';
        &:hover:not(.kr-loading):not(.kr-locked) {
          border: 1px solid $color-primary;
          background-color: #fff;
        }

        &:focus:not(.kr-loading):not(.kr-locked) {
          background-color: #fff;
        }

        &.kr-no-icons {
          padding: 12px 20px 12px 12px;
        }

        &.kr-loading {
          border: 1px solid $color-primary;
          background-color: #f4f4f4;
        }

        &.kr-locked {
          background-color: #eee;
          border: 1px solid #ddd;
          cursor: not-allowed;
    
          label {
            color: #999;
            cursor: not-allowed;
          }
        }

        &.kr-cards {
          padding: 12px 20px 12px 12px;
        }

        // Icon
        .kr-method-icon {
          display: flex;
          top: 0;
          left: 15px;
          height: 100%;
          flex-direction: column;
          justify-content: center;

          svg {
            fill: $color-primary;
          }
        }

        // Label
        label {
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          color: #444;
          transition: .3s color;
        }

        // Arrow
        .kr-arrow-right svg {
          fill: $color-primary;
          display: none;
        }

        // Loading animation
        .kr-loading-animation {
          display: block;
          position: absolute;
          right: 10px;
          width: 20px;
          height: 20px;

          @import '../../no-theme/smartform/loading_animation';
        }
      }

      .kr-methods-group {
        .kr-methods-group-activator {
          padding: 15px 20px;
          display: flex;
          justify-content: flex-start;
          @include column-gap(5px);
          @import './card_tooltip';

          .kr-method-icon {
            position: relative;
            display: block;
            left: 0;
          }

          .kr-extra-brands {
            color: #777;
            font-family: "Roboto", sans-serif;
            margin-left: 5px;
            font-size: 13px;
          }

          label {
            flex-grow: 1;
            text-align: end;
          }
        }
      }
    }
  }

  .kr-methods .kr-method-content .kr-method-label {
    @extend %font-style;
  }
}
