.kr-popin-modal-header {
  height: 90px;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  border-bottom: 1px solid #808080;
  margin-bottom: $top-margin;

  &.kr-popin-header-layer {
    height: 0;
  }

  .kr-popin-modal-header-image {
    img.kr-header-logo {
      background-color: #000;
    }
  }

  i.fi-x-circle.icon-close-popup {
    width: 12px;
    height: 12px;
    top: 0;
    right: 0;
    margin: 7px 7px 0 0;
  }

  &.kr-secure-iframe-visible {
    height: 30px;
    border-bottom: 0;
    margin-bottom: 0;

    i {
      &.fi-x-circle.icon-close-popup {
        margin-top: 0;
      }

      &.fi-lock {
        margin-left: 7px;
      }
    }
  }

  span.kr-popin-shop-name {
    margin: 40px 0 0 0;
    padding: 0 5px;
    text-align: center;

    span {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      line-height: 19px;
    }
  }
}