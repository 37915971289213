.kr-popin-modal-header.kr-smartform-modal-header {
  box-sizing: border-box;
  border: 5px solid #f4f4f4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100px;

  // Close button
  i.fi-x-circle.icon-close-popup {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    top: 0;
    right: 0;
    margin: 5px 5px 0 0;
  }

  &.kr-secure-iframe-visible i.fi-x-circle.icon-close-popup {
    margin: 0;
  }

  // Shop name
  .kr-popin-shop-name {
    left: 10px;
    top: 3px;
    padding: 3px 0 7px 0;
    border-bottom: 1px solid $color-primary;

    span {
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      color: #4a4a4a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: $shop-name-width;
      display: block;
    }
  }

  // Shop logo
  .kr-popin-modal-header-image {
    bottom: 8px;

    .kr-header-border {
      width: 34px;
      height: 34px;
      background-color: $color-primary;
    }

    .kr-header-logo {
      width: 32px;
      height: 32px;
    }
  }

  // Amount + orderID
  span.kr-popin-amount,
  span.kr-popin-order-id {
    right: unset;
    left: 60px;
    display: block;

    span {
      font-family: 'Roboto', sans-serif;
    }
  }

  span.kr-popin-order-id {
    bottom: 8px;
  }

  span.kr-popin-amount {
    bottom: 25px;

    span {
      color: $color-primary;
      font-size: 18px;
    }
  }

  // Iframe open
  &.kr-secure-iframe-visible {
    height: 30px;
    margin-bottom: 0;
    background-color: #f5f5f5;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1px solid #cccccc;

    .kr-popin-header {
      font-family: 'Roboto', sans-serif;
      color: #666;
      line-height: unset;
    }

    .fi-lock {
      box-sizing: border-box;
      order: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}