.kr-modal-wrapper .kr-modal {
  box-shadow: 0 0 15px #121212;

  .kr-modal-header .kr-modal-header-close-button {
    display: block;
  }

  .kr-modal-header .kr-modal-header-close-button svg path {
    fill: $color-primary;
  }

  .kr-modal-content {

    p,
    a {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: #4A4A4A;

      & b,
      & strong {
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
      }

      &.kr-modal-card,
      &.kr-token {
        color: #4A4A4A8F;
      }
    }

    .kr-modal-optional-content {
      p {
        color: #5694C7;
      }
    }
  }

  .kr-modal-footer {
    button {
      font-family: 'Roboto', sans-serif;
      width: 30%;
      margin: 0;

      &,
      &.kr-cancel,
      & span {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
}