@mixin buttons() {
  margin-bottom: 7px;
  width: 100%;
  border: 0;
}

.kr-modal-wrapper .kr-modal .kr-modal-footer button,
.kr-payment-button {
  box-sizing: border-box;
  @include buttons();
  background-color: #293c7a;
  color: $color-light;
  padding: 12px 0;
  font-size: 1em;
  outline: none !important;
  height: 46px;
  font-family: inherit;
  cursor: pointer;
  transition: box-shadow 0.4s;

  &.kr-cancel {
    background-color: $color-grey-font;
  }

  span {
    user-select: none;
    display: inline-block;
    width: 100%;
    line-height: 22px;
    color: inherit;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
  }
}

.kr-payment-button:focus {
  background-color: #293c7a;
  color: $color-light;
  font-family: 'Roboto', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
