.kr-finish-page {
  width: 100%;
  min-height: 354px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  // Label
  .kr-finish-label {
    position: absolute;
    top: 210px;
    animation: label-trans 0.2s ease-out;

    label {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: #4caf50;
      text-transform: uppercase;
    }
  }

  &.kr-running {
    .kr-finish-label label {
      color: $color-primary;
    }
  }
}

// Success animation
.kr-finish-page .kr-animation {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  position: absolute;
  top: 104px;
  animation: animation-trans 0.15s ease-out;

  .kr-check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #fff;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #fff;
      transform: rotate(-45deg);
    }

    .kr-icon-line {
      height: 5px;
      background-color: #fff;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.kr-line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.kr-line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .kr-icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.6);
      background-color: #4caf50;
    }

    .kr-icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #fff;
    }
  }
}
// Running animation
.kr-finish-page.kr-running {
  min-height: 280px;
  // Loading animation
  .kr-loading-animation {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 104px;
    animation: animation-trans 0.15s ease-out;

    @import './loading_animation';

    i.kr-loader::before {
      top: calc(50% - 30px);
      left: calc(50% - 30px);
    }

    i.kr-loader::before,
    i.kr-loader::after {
      width: 30px;
      height: 30px;
      animation-duration: 1.5s;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animation-trans {
  0% {
    top: 184px;
  }
  25% {
    top: 154px;
  }
  50% {
    top: 124px;
  }
  75% {
    top: 114px;
  }
  100% {
    top: 104px;
  }
}
@keyframes label-trans {
  0% {
    top: 290px;
  }
  25% {
    top: 270px;
  }
  50% {
    top: 250px;
  }
  75% {
    top: 230px;
  }
  100% {
    top: 210px;
  }
}
