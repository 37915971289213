/* BREAKPOINT VARIABLES */
$breakpoint-popin: 1px 450px;

$sides-margin: 50px;
$top-margin: 16px;
$bottom-margin: 30px;

/* Components */
@import './button';

kr-smartform[kr-popin] {
  @import './layer';
}

.kr-embedded[kr-popin] {
  display: none;
  box-shadow: 0 0 15px #121212;
  transition: height 1s;
  width: $form-width + $sides-margin;
  margin: 0;

  @import './header';
  @import './field';
  @import './wrappers';
  @import './error';
  @import './payment_button';
  @import './help-button';
  @import './footer';
  @import './wallet_tabs';
  @import './wallet_card_list';
  @import './layer';
  @import './delete-button';

  &.kr-internet-explorer {
    @import './internet-explorer';
  }
}

.kr-popin-wrapper {
  @media (max-width: 450px) {
    padding-top: 45px;
  }
}
