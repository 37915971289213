.kr-error-animation {
    &.kr-select-wrapper select,
    &.kr-select-wrapper .kr-select span.kr-selected-option,
    &.kr-text-wrapper input {
        animation: shake-anim 0.5s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}
@keyframes shake-anim {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}