.kr-embedded,
.kr-checkout,
.kr-smartform,
.kr-smart-button-wrapper {
  @import 'initial';
  @import 'normalize';
}

@import 'package.no-theme';
@import 'package.classic';
