.kr-card-header {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  background-color: #f4f4f4;
  padding: 32px 14px 12px;
  margin-bottom: 14px;
  border-bottom: 1px solid $color-primary;
  border-left: 1px solid #b7b7b7;
  border-right: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  
  span.kr-card-header-label {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    top: 10px;
    left: 11px;
  }

  @import './card_icons';

  // Overrride tooltip position
  .kr-card-icons span.kr-extra-brands .kr-method-tooltip-content {
    padding-top: 40px;
  }
}