/* Help */
.kr-field-wrapper .kr-help-button-wrapper {
  display: none;
}
.kr-field-wrapper .kr-help-button-wrapper.kr-inner {
  @include order(4);
  display: none;

  &.kr-visible {
    display: block;

    .kr-help-button {
      display: none;
    }
  }
}

/* Help modal */
.kr-help-button-wrapper .kr-help-modal-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(row);
}
.kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal-background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0.7;
  background-color: #232323;
}
.kr-help-button-wrapper .kr-help-modal-wrapper .kr-help-modal {
  box-sizing: border-box;
  width: 300px;
  padding: 20px;
  position: relative;
  z-index: 1;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(row);

  .kr-help-modal-header {
    display: block;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .kr-help-modal-close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .kr-help-modal-content {
    width: 100%;
    display: block;
    user-select: none;
  }

  .kr-help-modal-content > div.kr-help-content,
  .kr-help-modal-content > div {
    display: block;
  }

  .kr-help-modal-content > div.kr-help-content {
    margin: 10px 0;
    font-size: 14px;
  }

  .kr-help-modal-content svg,
  .kr-help-modal-content svg image {
    width: 100%;
  }

  .kr-help-modal-content svg {
    height: 106px;
  }

  .kr-help-modal-content svg image {
    height: 100%;
  }
}
