// Disabled state
.kr-inner-wrapper.kr-disabled input.kr-input-field {
  color: $color-grey-font;
}

.kr-pan,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-identity-document-type,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-installment-number,
.kr-first-installment-delay {
  &.kr-disabled {
    background-color: $color-disabled !important;
  }

  .kr-field-component.kr-disabled,
  .kr-help-button-wrapper.kr-disabled {
    background-color: $color-disabled;

    .kr-iframe-wrapper {
      background-color: $color-disabled;
    }
  }

  .kr-icon.kr-disabled {
    top: 0px;
  }
}

.kr-identity-document-number,
.kr-identity-document-type,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-installment-number,
.kr-first-installment-delay {
  &.kr-disabled {
    .kr-selected-option,
    input {
      background-color: $color-disabled !important;
      color: #545454 !important;
    }

    .kr-icon-wrapper-root {
      background-color: $color-disabled;
    }
  }
}

.kr-outer-wrapper.kr-disabled,
.kr-inner-wrapper.kr-disabled {
  background-color: $color-disabled;
}

&.kr-ios .kr-inner-wrapper.kr-disabled input.kr-input-field,
&.kr-safari .kr-inner-wrapper.kr-disabled input.kr-input-field {
  color: #222;
}
