@import './mixins/no_select';
@import './mixins/flex';
@import './mixins/boxes';

@import './package/no-theme/variables';
@import './package/no-theme/form';
@import './package/no-theme/popin';
@import './package/no-theme/brand-button';

.kr-smartform {
  z-index: 1;
  @import './package/no-theme/smartform/index';
  @import './package/no-theme/help';
  @import './package/no-theme/modal';
  @import './package/no-theme/dialog';
}

@import './package/no-theme/layer';
@import './package/no-theme/smartform/smart_button';

.kr-embedded {
  position: relative;
  @import './package/no-theme/icon';
  @import './package/no-theme/field';
  @import './package/no-theme/help';
  @import './package/no-theme/select';
  @import './package/no-theme/shake';
  @import './package/no-theme/custom_field';
  @import './package/no-theme/wallet_tabs';
  @import './package/no-theme/wallet_card_list';
  @import './package/no-theme/visibility_button';
  @import './package/no-theme/layer';
  @import './package/no-theme/modal';
  @import './package/no-theme/error';
  @import './package/no-theme/delete_button';
  @import './package/no-theme/smartform/card_header';
  @import './package/no-theme/dialog';

  /* Payment button CSS */
  button::-moz-focus-inner {
    border: 0;
  }

  /* Reset CSS */
  input.kr-input-field {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;
    box-shadow: initial;
    transition: initial;
    height: 1.05em;
    width: 230px;
    line-height: 1em;
    font-size: 1em;
  }

  /* Form */
  #krFieldRepresentation,
  #krFieldRepresentationError,
  #krFieldRepresentationDisabled {
    position: fixed;
    top: -10000px;
    left: -10000px;
  }
}

iframe#krcheckout {
  all: initial;
}

/* ALIAS .kr-smart-form for .kr-smartform */
.kr-smart-form {
  @extend .kr-smartform;
}