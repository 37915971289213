@mixin buttons() {
  box-sizing: border-box;
  margin-bottom: 7px;
  text-align: center;
  width: 100%;
  border: 0;
}

.kr-form-error {
  box-sizing: border-box;
  @include buttons();
  border: 0;
  background-color: #b71c1c;
  display: block;

  span {
    user-select: none;
    box-sizing: border-box;
    color: #fff !important;
    padding: 10px 3px;
    display: inline-blocK;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    cursor: default;
  }
}

.kr-form-error-visible {
  display: block;
}
