.kr-smartform-embedded-header {
  background-color: #f4f4f4;
  padding: 9px 15px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  @include row-gap(3px);
  border-bottom: 1px solid $color-primary;
  border-left: 1px solid #b7b7b7;
  border-right: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  .kr-amount-label,
  .kr-amount-figure {
    font-family: 'Roboto', sans-serif;
    user-select: none;
    cursor: default;
  }

  .kr-amount-label {
    font-size: 12px;
    color: #999;
  }

  .kr-amount-figure {
    color: $color-primary;
    font-size: 17px;
    padding-left: 5px;
  }
}
