$modal-width: 300px;
$top-margin: 6px;

.kr-smartform-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .kr-smartform-modal-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #232323;
    z-index: 1;

    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.kr-modal-open {
      opacity: 0.7;
    }
  }

  .kr-smartform-modal {
    min-width: $modal-width;
    z-index: 2;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: $footer-height;
    padding-top: $header-height + $top-margin;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    opacity: 0;
    transform: scale(0.7);
    transition: all 0.15s ease-out;
    transition-property: opacity, transform;

    &.kr-modal-open {
      opacity: 1;
      transform: scale(1);
    }

    &.kr-secure-iframe-open,
    &.kr-secure-iframe-visible {
      padding-top: 30px;
      padding-bottom: 0;

      @import './mobile';
    }

    @import './methods_list';
    @import './modal_back_button';
    @import './finished_payment';
    @import '../popin/smartform/header';
    @import '../popin/footer';

    &.kr-fullscreen,
    &.kr-fullscreen .kr-smartform-methods {
      width: 100%;
      height: 100%;

      .kr-method-selected.kr-secure-iframe-open {
        width: 100%;
        height: 100%;
      }
    }
  }

  @import '../../../mixins/smartform/modal';
  @include modal-container($modal-width);
}

&.kr-secure-iframe-visible .kr-smartform-modal-wrapper {
  z-index: 10000;
}