$margin: 15px;

.kr-modal-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none;

  .kr-modal {
    margin: 0;
    background-color: #ffffff;
    width: 375px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;

    .kr-modal-header {
      display: block;
      height: 22px;
      padding: 10px;
      position: absolute;
      top: 0;
      right: 0;

      .kr-modal-header-close-button svg {
        cursor: pointer;
        display: block;
        height: 20px;
        width: 20px;

        path {
          fill: #808080;
        }
      }
    }

    .kr-modal-content {
      padding: $margin*2;
      box-sizing: border-box;
      width: 100%;

      p {
        display: block;
        text-align: left;
        margin: $margin 0;
        width: 100%;

        &.kr-token {
          text-align: left;
        }

        &.kr-modal-card {
          font-size: 16px;
          text-align: left;
        }
      }

      .kr-modal-optional-content {
        display: table;
        margin-top: $margin*2;
        padding-top: $margin;
        border-top: solid #5694C7 1px;
        width: 60%;

        .kr-phone,
        .kr-mail {
          display: table-row;

          p,
          a {
            display: table-cell;
          }

          a {
            cursor: pointer;
            color: #5694C7;
          }
        }
      }

      .kr-help-image {
        display: block;
        width: 100%;
        margin-top: $margin * 1.5;
      }
    }

    .kr-modal-footer {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 $margin $margin $margin;
      box-sizing: border-box;

      button {
        margin: 0;
        cursor: pointer;
        text-align: center;

        &.kr-cancel {
          margin-right: $margin;
        }
      }
    }
  }
}
