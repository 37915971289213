.kr-animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.kr-animated.infinite {
  animation-iteration-count: infinite;
}

.kr-animated.hinge {
  animation-duration: 2s;
}

.kr-animated.flipOutX,
.kr-animated.flipOutY,
.kr-animated.bounceIn,
.kr-animated.bounceOut {
  animation-duration: .75s;
}
