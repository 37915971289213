.kr-icon-wrapper-root {
  position: relative;
  background-color: $color-light;
  width: $icon-width - 1px;
  height: 31px;
}

.kr-icon-wrapper-root .kr-icon {
  width: $icon-width - 1px;
  height: 31px;
}

.kr-icon {
  background-color: $color-light;
  display: block;

  &.kr-disabled {
    background-color: $color-disabled;
  }
}

.kr-icon-wrapper,
.kr-icon-inner-wrapper,
.kr-icon-wrapper-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(row);
}

.kr-icon-wrapper svg {
  width: 28px;
  height: 20px;
}

.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-field.kr-text {
  .kr-icon-wrapper-root {
    padding: 1px 1px 1px 0;
  }
}