.kr-brand-buttons,
.kr-embedded .kr-brand-buttons {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  width: 100%;
  min-height: 40px;

  .kr-brand-button {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
  }

  .kr-brand-button .kr-brand-button-icon {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);
    height: 40px;
    width: 50px;
    position: relative;
    cursor: pointer;

    svg {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .kr-brand-button .kr-brand-button-label {
    text-align: center;
    text-transform: lowercase;
  }
}
