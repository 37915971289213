.kr-smartform-modal-button {
  box-sizing: border-box;
  background-color: #293c7a;
  height: 46px;
  width: $smartform-width;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none !important;
  cursor: pointer;
  margin: 5px 0;

  span {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  // Loading state
  &.kr-loading {
    display: block;

    span {
      display: none;
    }

    .kr-spinner {
      display: block;
    }
  }

  @import './../../classic/spinner';
}