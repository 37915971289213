.kr-field {
  &.kr-visible {
    display: block;
  }

  input[type='text'],
  &.kr-radio label,
  label.kr-checkbox {
    width: 100%;
  }

  input[type='text'],
  &.kr-radio input[type='radio'],
  .kr-checkbox input[type='checkbox'] {
    background-color: initial;
    padding: initial;
    border: initial;
    margin: initial;
    cursor: default;
  }
}

// Checkbox
.kr-field.kr-checkbox label.kr-field-control.kr-checkbox > input {
  display: none;
}

.kr-field.kr-checkbox {
  .kr-checkbox-control-indicator {
    background-color: #e6e6e6;
    border: 1px solid #b1b1b1;
    height: 12px;
    width: 12px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .kr-label {
    padding-left: 5px;
    display: inline-block;
  }

  // Hover and focus
  .kr-field-control:hover input ~ .kr-checkbox-control-indicator,
  .kr-field-control input:focus ~ .kr-checkbox-control-indicator {
    background-color: #cccccc;
  }

  // Checked
  .kr-field-control input:checked ~ .kr-checkbox-control-indicator,
  .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
    background-color: #717171;
  }

  // Hover when checked
  .kr-field-control:hover
    input:not([disabled]):checked
    ~ .kr-checkbox-control-indicator,
  .kr-field-control input:checked:focus ~ .kr-checkbox-control-indicator {
    background-color: #656565;
  }

  // Hide default browser input
  .kr-field-control input:disabled ~ .kr-checkbox-control-indicator {
    background-color: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }

  // Disabled tick colour
  .kr-field-control input:disabled ~ .kr-checkbox-control-indicator:after {
    border-color: #7b7b7b;
  }
}
