$color-background: #000;
$light-font-color: #fff;

.kr-popin-utils button.kr-popin-button {
  box-sizing: border-box;
  background-color: #293c7a;
  border: 0;
  color: $light-font-color;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  height: 46px;
  margin: 0px;
  outline: none !important;
  padding: 12px 0;
  text-align: center;
  width: 232px;
}
