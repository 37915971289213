.kr-smartform-modal-wrapper {
  .kr-smartform-modal {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    padding-top: 106px;
    padding-bottom: 70px;

    @import './header';
    @import './methods_list';
    @import './modal_back_button';
    @import './footer';

    &.kr-secure-iframe-visible {
      padding-top: 30px;
      padding-bottom: 0;
    }

    .kr-embedded,
    .kr-embedded .kr-payment-button,
    .kr-embedded .kr-form-error {
      width: $inner-width;
    }
  }
}
