.kr-smart-button-wrapper {
  display: block;
  position: relative;
  margin: 5px 0;
  width: 230px;

  .kr-smart-button {
    display: block;
    background-color: #eee;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;

    @import './apple_pay';

    .kr-method-info {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
    }

    .kr-method-info.kr-hidden {
      visibility: hidden;
    }

    .kr-method-icon {
      display: none;
    }

    label {
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
    }

    /* Loading */
    &.kr-loading {
      border: 1px solid #666;
    }

    /* Disabled mode */
    &.kr-disabled {
      background-color: #eee;
      border: 1px solid #ddd;
      cursor: not-allowed;

      &:hover {
        border: 1px solid #aaa;
      }

      .kr-method-info,
      .kr-method-info .kr-method-icon,
      .kr-method-info .kr-label {
        cursor: not-allowed;
      }

      label {
        color: #999;
        cursor: not-allowed;
      }
    }

    // Card icons
    &.kr-cards {
      @import './card_icons';

      .kr-card-method-info .kr-card-icons .kr-extra-brands {
        font-size: 13px;
      }
    }
  }

  .kr-loading-animation {
    display: none;
  }
}