@import 'variables';
@import '../no-theme/field';

// Initial state (without content)
.kr-pan,
.kr-field.kr-text,
.kr-field.kr-checkbox,
.kr-expiry,
.kr-security-code,
.kr-identity-document-number,
.kr-card-holder-name,
.kr-card-holder-mail,
.kr-identity-document-type,
.kr-installment-number,
.kr-first-installment-delay,
& > .kr-field-wrapper {
  box-sizing: border-box;
  @include flexbox;
  position: relative;
  width: 100%;
  height: 35px;
  background-color: $color-light;
  border: 1px solid #b1b1b1;
  margin-bottom: 16px;
}

&:not([krvue]) {
  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  .kr-identity-document-type,
  .kr-installment-number,
  .kr-first-installment-delay,
  & > .kr-field-wrapper {
    height: 35px;
  }
}

// Rendered state (with content)
&[krvue] {
  .kr-pan,
  .kr-field.kr-text,
  .kr-field.kr-checkbox,
  .kr-expiry,
  .kr-security-code,
  .kr-identity-document-number,
  .kr-card-holder-name,
  .kr-card-holder-mail,
  & > .kr-field-wrapper {
    background-color: transparent;

    .kr-field-wrapper {
      height: 31px;
    }

    .kr-field-component {
      height: 33px;
      width: 100%;
      background-color: $color-light;
    }

    .kr-field-component input.kr-input-field {
      box-sizing: border-box;
      font-family: 'Roboto', sans-serif;
      color: #717171;
      padding: 4px 0 4px 9px;
      font-size: 14px;
      outline: 0;
      width: 100%;
      height: 33px;
      background-color: $color-light;

      @include placeholder() {
        color: $color-grey-placeholder;
        opacity: 1;
      }
    }
  }

  // Iframe fields
  .kr-pan,
  .kr-expiry,
  .kr-security-code {
    background-color: $color-light;
    padding: 1px;

    .kr-icon-wrapper-root,
    .kr-field-component {
      height: 31px;
      padding-top: 0px;
      top: 0px;
    }
  }

  // Custom fields
  .kr-field.kr-checkbox,
  .kr-field.kr-text {
    width: 100%;
    height: 35px;
  }

  .kr-field.kr-text {
    border: 1px solid #b1b1b1;

    .kr-icon-wrapper-root {
      border: 0;
    }
  }

  .kr-field.kr-checkbox {
    border: 0;
    padding-left: 0px;
    
    .kr-field-wrapper {
      align-items: center;
    }
  }
}

.kr-expiry.kr-size-half {
  margin-right: 10px;
}

.kr-inner-wrapper.kr-error {
  .kr-field-container {
    input.kr-input-field {
      color: red;
    }
  }
}

@mixin classicInput() {
  border: 0;
  color: #717171;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  outline: 0;
  padding: 4px 0;
  width: 100%;
  line-height: normal;
  margin: 2px 0 0 0;
}

.kr-field-container {
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 1px;
  display: block;

  input.kr-input-field {
    box-sizing: content-box;
    @include classicInput();
  }
}

.kr-field.kr-field-wrapper {
  .kr-icon {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(stretch);
    @include align-items(stretch);
    top: 0;

    .kr-icon-wrapper-content {
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);

      i {
        height: auto;
        max-width: 27px;
      }
    }
  }

  input {
    @include classicInput();

    @include placeholder() {
      color: #999;
      opacity: 1;
    }

    padding-left: 9px;
    padding-top: 8px;
  }
}
