.kr-back-button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 15px;
  cursor: pointer;
  width: calc(100% - 30px);
  margin-bottom: 10px;

  &:hover,
  &.kr-focus {
    background-color: #f4f4f4;
  }

  label {
    cursor: pointer;
    color: $color-primary;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-left: 35px;
  }

  .kr-arrow-left {
    display: flex;
    left: 14px;
    position: absolute;
    height: 100%;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  
    svg {
      fill: $color-primary;
      height: 20px;
      width: 20px;
    }
  }

}
