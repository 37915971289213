// Card icons
.kr-card-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @include column-gap(6px);
  cursor: pointer;

  span {
    height: 22px;
    width: 30px;
    cursor: pointer;

    &.kr-extra-brands {
      color: #777;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      padding-left: 2px;

      @import './card_tooltip';
    }

    // Border
    svg {
      height: 22px;
      width: 30px;
      padding: 2px 4px;
      box-shadow: rgb(0 0 0 / 7%) 0px 6px 24px 0px,
        rgb(0 0 0 / 24%) 0px 0px 0px 1px;
      height: 18px;
      width: 22px;
      border-radius: 0px;
    }

    // Amex
    &.kr-amex svg {
      background-color: #006fce;
    }

    &.kr-cb,
    &.kr-cb svg {
      padding: 0px;
      height: 22px;
      width: 33px;
    }

    &.kr-visa svg,
    &.kr-visa_electron svg,
    &.kr-visa_debit svg {
      padding: 0px 2px;
      height: 22px;
      width: 26px;
    }
  }
}