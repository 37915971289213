.kr-method-tooltip-content {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
  opacity: 0;

  &.kr-visible {
    top: 0;
    opacity: 1;
  }

  &.kr-on-top {
    top: -53px;
  }

  .kr-card-tooltip, .kr-methods-group-tooltip {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span svg {
      cursor: default;
    }
  }
}
