// Menu element
&.kr-apple_pay:not(.kr-disabled) {
  padding: 0;
  background-color: transparent;
  border: none;

  // &.kr-disabled{
  //   cursor: not-allowed;
  // }

  .kr-apple-pay,
  .kr-apple-pay apple-pay-button {
    display: block;
    width: 100%;
    pointer-events: none;
  }
}